import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The Image component displays an image at the correct resolution based on the size it appears on the screen, Informative images that meet real user needs can be very important, especially where they help users identify specific context. Images help them orient themselves and they separate out the content, but having unnecessary or decorative images can frustrate users, especially on information pages or transactional journeys.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/images/image-component-guideline-anatomy.svg",
      "alt": "anatomy guideline image component"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Frame :`}</strong>{` A track that will be filled by the indicator either determinate or indeterminate way`}</li>
      <li parentName="ol"><strong parentName="li">{`Image :`}</strong>{` is a circular element that will fill the invisible circular track with color, as the indicator moves from 0 to 360 degrees.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Always ensure that images work properly on all screen sizes and when there’s text on top of the image. You can take the advantage of our positioning tools. Based on the need it is possible to position images on any given area. Even though the system is flexible and supports any number of different aspect ratios we have a set of preferred aspect ratios to use by default. In addition to consistency, these basic image aspect ratios ensure it’s easy to reuse images in different locations, example :`}</p>
    <div className="image-ratio-card">
      <h3><strong parentName="h3">{`1:1 Ratio`}</strong></h3>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Profile picture of avatar`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Profile thumbnail`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Customer review thumbnails`}</p>
        </li>
      </ul>
    </div>
    <div className="image-ratio-card">
      <h3><strong parentName="h3">{`7:3 | 8:5 | 16:9 Ratio `}</strong></h3>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Hero or banner images`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Service card or carousel`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Video embeds or preview`}</p>
        </li>
      </ul>
    </div>
    <div className="image-ratio-card">
      <h3><strong parentName="h3">{`4:3 | 10:13 Ratio `}</strong></h3>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Mobil banner images`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Mobile hero or story block`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Pop up images`}</p>
        </li>
      </ul>
    </div>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> You have long content that you want to keep out of sight at first.</li>
      <li> To keep content like structured navigation or screen-specific help accessible. </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> To force users to complete an action before continuing </li>
      <li> For small content or few options closer to the original context </li>
      <li> For small bits of information focused on a single action</li>
    </ul>
  </div>
    </div>
    <p>{`Make sure that the images are appropriately sized for all screen sizes. This is important especially on mobile devices to avoid unnecessarily long loading times. The lowest resolution should work fine on mobile phones without being too large. 400 pixels is a good minimum size. The maximum resolution should be twice the maximum width the image can have, to accommodate screens with retina resolution. However, you don’t need to create images with anything more than 1920px wide, otherwise the file size will be too large.`}</p>
    <p>{`Feel free to use modern image formats such as WebP These tend to take up less space for a similar size than traditional formats such as jpg, and are supported by all browsers we have support for in legion. Do not use png for photographs, only for graphics and svg only for vector.`}</p>
    <p><strong parentName="p">{`Common places radio button appear`}</strong>{` : Banner, Hero, Card, etc.`}</p>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Use image with minimum quality image and ration for banner" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/images/guideline/image-component-guideline-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use image with low quality image and ration for banner" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/images/guideline/image-component-guideline-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`When using images on a page, you must provide an alternate method for that content. This can be provided in multiple ways. You can provide this information with a caption, alt attribute (alt tag), title attribute, or aria-label attribute. If an image has text, all the text in the image must be provided in the alternate content, this makes sure that people with disabilities can understand them. No matter which method is used, an alt or title attribute must be provided, even if the attribute is blank.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      